<template>
    <div>
        <v-alert class="mx-8 my-8" v-if="msg" type="error" dismissible>{{ msg }}</v-alert>
        <div class="text-center mt-4">
            <span class="text-h6 mr-4">Current Plan</span>
            <v-btn x-small @click="refreshSubscription()" text><v-icon
                    class="primary-color">mdi-refresh</v-icon></v-btn>
        </div>
        <v-card v-if="currentPlan" class="mx-auto" flat max-width="600">
            <v-card-text>
                <v-card elevation="15">
                    <v-card-text>
                        <v-row align="center">
                            <v-col> <v-card-title>{{ currentPlan?.plan?.name }}<div class="text-right"><v-chip x-small
                                            class="success" label>{{
                                                $store.state.vendor.accountStatus }}</v-chip></div></v-card-title>
                                <v-card-subtitle>Rs. {{ currentPlan?.plan?.amount }}/- x
                                    {{ currentPlan.quantity }} Unit</v-card-subtitle>
                                <v-card-subtitle class="mt-n7">Billed every {{ currentPlan?.plan?.interval }} {{
                                    currentPlan?.plan?.frequency.toLowerCase().replace('ly',
                                        '')
                                }}</v-card-subtitle></v-col>
                        </v-row>
                        <row-item label="Payment Method">{{ currentPlan.paymentMethod }}</row-item>
                        <modules-list :allowedModules="currentPlan.plan.allowedModules"
                            v-if="currentPlan.plan.allowedModules.length > 0"></modules-list>
                        <a class="ml-4 text-decoration-underline" :href="currentPlan.shortUrl" target="_blank"
                            v-if="$store.state.vendor.accountStatus == 'CREATED'">{{
                                currentPlan.shortUrl }}</a>
                        <v-row>
                            <v-col>
                                <row-item v-if="currentPlan.currentStartAt" label="Last bill">{{
                                    $options.filters.dateFormat(new
                                        Date(currentPlan.currentStartAt *
                                            1000)) }}</row-item>
                                <row-item v-else label="Trial Period Left">{{
                                    trialDaysLeft(currentPlan.nextChargeAt) }} Day(s)</row-item>
                            </v-col>
                            <v-col>
                                <row-item label="Next bill">{{ new Date(currentPlan.nextChargeAt * 1000) |
                                    dateFormat }}</row-item>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <div class="text-right pa-4">
                        <!-- <v-btn class="mr-2" v-if="$store.state.vendor.accountStatus == 'ACTIVE'">Pause</v-btn> -->
                        <!-- <v-btn class="mr-2" v-if="$store.state.vendor.accountStatus == 'PAUSED'">Resume</v-btn> -->
                        <v-alert dense text type="info" v-if="currentPlan.hasScheduledChanges">
                            <v-row align="center">
                                <v-col class="text-left">
                                    <labe v-if="currentPlan.earlyUpgrade">Early Upgrade Activated! Enjoy immediate
                                        benefits</labe>
                                    <label v-else>Scheduled Plan Upgrade on {{ new Date(currentPlan.changeScheduledAt *
                                        1000)
                                        | dateFormat }}</label>
                                </v-col>
                                <v-col class="text-right"><v-btn small class="mr-2" :loading="cancelingUpgrade"
                                        @click="cancelUpgrade($store.state.vendor.licensingDetails.subscriptionId)"
                                        outlined light v-if="!currentPlan.earlyUpgrade">Cancel
                                        Upgrade</v-btn></v-col>
                            </v-row>
                        </v-alert>
                        <v-btn outlined class="mr-2"
                            @click="verifySubscription($store.state.vendor.licensingDetails.subscriptionId)"
                            v-if="$store.state.vendor.accountStatus == 'CREATED'">Verify</v-btn>
                        <v-btn outlined class="mr-2" color="red" small @click="cancelSubscriptionDialog = true"
                            v-if="$store.state.vendor.accountStatus !== 'CANCELLED'">Cancel Subscription</v-btn>
                    </div>
                </v-card>
            </v-card-text>
        </v-card>
        <h2 class="text-center" v-if="!currentPlan">Choose your subscription</h2>
        <h2 class="text-center" v-else>Upgrade your subscription</h2>
        <v-card class="mx-auto" flat max-width="1200">
            <v-card-text>
                <vendor-plans :vendorDetails="$store.state.vendor"
                    @success="handleSubscriptionSuccess($event)"></vendor-plans>
            </v-card-text>
        </v-card>
        <app-dialog :show="cancelSubscriptionDialog" title="Cancel subscription"
            @close="toggleCancelSubscriptionDialog()">
            <v-card flat>
                <v-card-text>
                    <label class="text-h6" color="red-text"><b>Please Note:</b>
                        <p>This action cannot be undone.</p>
                    </label>
                    <v-radio-group label="Schedule at" v-model="cancelSubscription">
                        <v-radio label="Immediate" :value="0"></v-radio>
                        <v-radio label="End of Billing Cycle" :value="1"></v-radio>
                    </v-radio-group>
                    <div class="text-right">
                        <v-btn :loading="cancelingSubscription"
                            @click="cancelSubscriptionMethod($store.state.vendor.licensingDetails.subscriptionId)">Cancel
                            Subscription</v-btn>
                        <v-btn @click="toggleCancelSubscriptionDialog()">Close</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </app-dialog>
    </div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import VendorPlans from '../components/VendorPlans.vue'
import ModulesList from '../components/ModulesList.vue'
import moment from 'moment';

export default {
    components: { VendorPlans, ModulesList },
    data() {
        return {
            currentPlan: undefined,
            showPlansPopup: false,
            cancelSubscription: 0,
            cancelSubscriptionDialog: false,
            cancelingSubscription: false,
            cancelingUpgrade: false
        }
    },
    mounted() {
        this.initComponent()
    },
    methods: {
        async initComponent() {
            if (this.$store.state.vendor?.licensingDetails?.planId) {
                this.currentPlan = await this.getItem(`${appConstants.BILLINGS_API}/subscriptions/${this.$store.state.vendor.licensingDetails.subscriptionId}`)
            }
        },
        async cancelUpgrade(subscriptionId) {
            try {
                this.cancelingUpgrade = true
                await this.putItem(`${appConstants.BILLINGS_API}/subscriptions/${subscriptionId}/cancelUpgrade`)
                this.refreshSubscription()
                this.initComponent()
            } catch (error) {
                console.error(error);
                this.msg = error?.response?.data
            } finally {
                this.cancelingUpgrade = true
            }
        },
        async cancelSubscriptionMethod(subscriptionId) {
            try {
                this.cancelingSubscription = true
                await this.putItem(`${appConstants.BILLINGS_API}/subscriptions/${subscriptionId}/cancel`, { cancelSubscription: this.cancelSubscription })
            } catch (error) {
                this.msg = error?.response?.data
            } finally {
                this.toggleCancelSubscriptionDialog()
                this.cancelingSubscription = false
                this.refreshSubscription()
            }
        },
        toggleCancelSubscriptionDialog() {
            this.cancelSubscriptionDialog = !this.cancelSubscriptionDialog
        },
        async handleSubscriptionSuccess(response) {
            this.refreshSubscription()
            this.initComponent()
        },
        async verifySubscription(subscriptionId) {
            try {
                await this.putItem(`${appConstants.BILLINGS_API}/subscriptions/${subscriptionId}/verify`)
                this.refreshSubscription()
            } catch (error) {
                this.msg = error?.response?.data
            }
        },
        trialDaysLeft(unixTimestamp) {
            const today = moment();
            const targetDate = moment.unix(unixTimestamp);
            const daysLeft = targetDate.diff(today, 'days');
            return daysLeft < 0 ? 0 : daysLeft;
        }
    },
}
</script>

<style lang="scss" scoped>
.format {
    padding-top: 5%;
}

h3,
h1 {
    color: white
}

h2 {
    margin: 1% !important;
    font-weight: bold;
}

.background {
    background: orange !important;
}

.activeBtn {
    background: #4b443c !important;
    color: white
}
</style>
